import React, { forwardRef, ReactNode } from 'react';
import styled from '@emotion/styled';

import { Gray_Light_White } from '@common/styles';
import { ZigzagTopNavigation } from '@common/top-navigation';

export interface BaseNavigationProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  right?: ReactNode;
}

export const BaseNavigation = forwardRef<HTMLDivElement, BaseNavigationProps>(
  ({ title, right, ...container_props }, ref) => {
    return (
      <Wrapper ref={ref} {...container_props}>
        <ZigzagTopNavigation.DetailsBasic title={title}>{right}</ZigzagTopNavigation.DetailsBasic>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  height: 44px;
  background: ${Gray_Light_White};
`;
