import React, { createContext, useContext } from 'react';

export interface ZigzagTopNavigationContextType {
  logo?: React.ReactNode;
  ItemIcon?: {
    CategoryIcon?: React.ReactNode;
    SearchIcon?: React.ReactNode;
    CartIcon?: React.ReactNode;
    HomeIcon?: React.ReactNode;
    MypageIcon?: React.ReactNode;
  };
}

const ZigzagTopNavigationContext = createContext<ZigzagTopNavigationContextType>({});

export interface ZigzagTopNavigationProviderProps extends ZigzagTopNavigationContextType {
  children?: React.ReactNode;
}

export const ZigzagTopNavigationProvider = (props: ZigzagTopNavigationProviderProps) => {
  const { children, ...value } = props;
  return <ZigzagTopNavigationContext.Provider value={value}>{children}</ZigzagTopNavigationContext.Provider>;
};

export const useZigzagTopNavigationContext = () => {
  const context = useContext(ZigzagTopNavigationContext);

  return context;
};
