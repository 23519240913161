import React from 'react';
import { IconArrowLeftBold, IconCloseBold, Text } from '@croquiscom-pvt/zds';
import { vars } from '@croquiscom-pvt/zds/dist/tokens';
import { css } from '@emotion/react';
import clsx from 'clsx';

import { BaseTopNavigation } from '../../base';
import { shouldCancelNavigation } from '../../utils/shouldCancelNavigation';
import type { ZDSComponentCommonProps } from '../../utils/types';
import { baseButtonCss } from '../common/styles';

export interface ZigzagTopNavigationDetailsBasicProps extends ZDSComponentCommonProps {
  /**
   * @description transparent 옵션을 사용하면 배경색이 투명하게 처리됩니다. (기획전 등 배경이 이미지인 경우 사용)
   * @default false
   */
  transparent?: boolean;
  title?: React.ReactNode;
  /**
   * @default 'arrow'
   */
  backIconType?: 'arrow' | 'close';
  children?: React.ReactNode;

  onBackClick?: (event: React.MouseEvent<HTMLElement>) => void | boolean;
}

const rootCss = css`
  &.transparent {
    background-color: ${vars.color.transparent};
  }
`;

const leftGroupCss = css`
  position: relative;
  margin-left: 6px;
`;

const centerGroupCss = css`
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const rightGroupCss = css`
  position: relative;
  margin-left: auto;
  margin-right: 6px;
`;

const titleCss = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

declare const window: Window & {
  __TOSS_API__?: {
    goBack: () => void;
  };
};

export const ZigzagTopNavigationDetailsBasic = React.forwardRef(function ZigzagTopNavigationDetailsBasic(
  props: ZigzagTopNavigationDetailsBasicProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { className, transparent, title, backIconType = 'arrow', children, onBackClick } = props;

  const handleBackClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    // onBackClick 이벤트의 리턴 값이 true인 경우 뒤로가기 동작을 막습니다.
    if (await shouldCancelNavigation(onBackClick?.(event))) {
      return;
    }

    if (window.__TOSS_API__) {
      window.__TOSS_API__.goBack();
      return true;
    }

    window.history.back();
  };

  return (
    <BaseTopNavigation.Root css={rootCss} ref={ref} className={clsx([className, transparent])} size='small'>
      {/* center */}
      {title && (
        <BaseTopNavigation.Group css={centerGroupCss}>
          <Text css={titleCss} variant='Body_18_Bold'>
            {title}
          </Text>
        </BaseTopNavigation.Group>
      )}
      {/* left */}
      <BaseTopNavigation.Group css={leftGroupCss}>
        <button
          css={[
            baseButtonCss,
            css`
              appearance: none;
              border: none;
              cursor: pointer;
              padding: 0;
              margin: 0;
              background-color: ${vars.color.transparent};
              box-sizing: border-box;
            `,
          ]}
          onClick={handleBackClick}
        >
          <BaseTopNavigation.ItemIcon>
            {backIconType === 'arrow' ? (
              <IconArrowLeftBold color='iconStrong' size={32} />
            ) : (
              <IconCloseBold color='iconStrong' size={32} />
            )}
          </BaseTopNavigation.ItemIcon>
        </button>
      </BaseTopNavigation.Group>
      {/* right */}
      <BaseTopNavigation.Group css={rightGroupCss}>{children}</BaseTopNavigation.Group>
    </BaseTopNavigation.Root>
  );
});
