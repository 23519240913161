export async function shouldCancelNavigation(onClickReturn: unknown): Promise<boolean> {
  if (isPromise(onClickReturn)) {
    const result = await onClickReturn;
    return typeof result === 'boolean' && result;
  }
  return typeof onClickReturn === 'boolean' && onClickReturn;
}

function isPromise(value: unknown): value is Promise<unknown> {
  return (
    value !== null &&
    (typeof value === 'object' || typeof value === 'function') &&
    'then' in value &&
    typeof value.then === 'function'
  );
}
