import { vars } from '@croquiscom-pvt/zds/dist/tokens';
import { css } from '@emotion/react';

export const baseButtonCss = css`
  transition: all 100ms linear;
  border-radius: ${vars.cornerRadius[8]};
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${vars.color.buttonSurfaceHovered};
    }
  }
  &:active {
    background-color: ${vars.color.buttonSurfacePressed};
  }
`;
